import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

let router = new VueRouter({
     mode: 'history',
    routes:[
        {path:'',  name:'Hoofdpagina', component: () =>import('./components/hoofdpagina')},
        {path:'/nieuws',  name:'Nieuws', component: () =>import('./components/nieuws')},
        {path:'/wie',  name:'Wie', component: () =>import('./components/wie')},
        {path:'/missie',  name:'Missie', component: () =>import('./components/missie')},
        {path:'/videoclipsenfotos',  name:'Fotofilm', component: () =>import('./components/fotofilm')},
        {path:'/sinterklaasshow',  name:'Sinterklaasshow', component: () =>import('./components/sinterklaasshow')},
        {path:'/voorstelling',  name:'Voorstelling', component: () =>import('./components/voorstelling')},
        {path:'/huisbezoek',  name:'Huisbezoek', component: () =>import('./components/huisbezoek')},
        {path:'/contact',  name:'Contact', component: () =>import('./components/contact')},
        {path:'/privacy-statement',  name:'Privacy', component: () =>import('./components/privacy')},
        {path:'/voorwaarden',  name:'Voorwaarden', component: () =>import('./components/voorwaarden')},
        {path:'/disclaimer',  name:'Disclaimer', component: () =>import('./components/disclaimer')},
        {path:'/roetveeg',  name:'Roetveeg', component: () =>import('./components/roetveeg')},
        {path:'/inkoorzingenwij',  name:'Koor', component: () =>import('./components/koor')},
    //    {path:'/corona',  name:'Corona', component: () =>import('./components/corona')},
        {path:'/algemene-voorwaarden',  name:'Voorwaarden', component: () =>import('./components/voorwaarden')},
    //    {path:'/videoboodschap/:id', name:'Videoboodschap', component: () =>import('./components/videoboodschap'), props:true,},
        {path:'/ziegindskomtdestoomboot',  name:'Spotify', component: () =>import('./components/spotify')},

        {
            path: "/:catchAll(.*)",
            name: 'NotFound',
            component: () => import('./components/notfound')
        }
    ],
    scrollBehavior () {
        return {x: 0, y: 0}
        
    }
});

export default router;