<template>
  <div id="app" class="bg">
    <b-container>
      <div v-b-visible.220="visibleHeaderHandler" />
      <kop />
      <navigation v-bind:nav-transparent="navTransparent" />
      <b-row>
        <b-col md="9">
          <router-view></router-view>
          <voettekst/>
        </b-col>
        <b-col md="3">
          <zijbalk/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import Navigation from './components/nav.vue'
  import Zijbalk from './components/zijbalk.vue'
  import Kop from './components/kop.vue'
  import Voettekst from './components/voettekst.vue'
  export default {
    name: 'App',
    data() {
      return {
        navTransparent: true
      }
    },
    components: {
      Navigation,
      Zijbalk,
      Kop,
      Voettekst
    },
    methods: {
      visibleHeaderHandler(isVisible) {
        this.navTransparent = isVisible;
      }
    }
  }
</script>



<style lang="scss">
  $media-url: 'https://media.ridderendeprins.nl';
  $container-max-widths: ( md: 720px, lg: 960px, xl: 1170px);

  @import url('https://fonts.googleapis.com/css?family=Lora:700');
  @import url('https://fonts.googleapis.com/css?family=Lora:70');
  @import url('https://fonts.googleapis.com/css?family=Quicksand');

  .social-icon {
    margin-right: 1px;
    margin-left: 1px;
  }
  .bg {
    background-image: url($media-url + '/images/RedP/bg/bg_stad_winter.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
  }
  .rowbg {
   background-color: rgba(28, 71, 151, 0.4);
   // background-color: #1b4695a1;
    padding-top: 20px;
  }
  .card {
    border: none !important; // border-radius: unset !important;
    border-radius: 0px 0px 5px 5px !important;
    margin: 0px 0px 20px 0px;
  }
  .card-body {
    padding: 2px 20px 25px 20px;
    padding-top: 0.5rem !important; // -webkit-box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.75);
    box-shadow: 5px 5px 20px 0 rgba(00, 00, 00, 0.75);
    border-radius: 0px 0px 5px 5px !important;
    font-family: "Quicksand", sans-serif;
    font-weight: 400; //display: flex;
    flex-direction: column;
  }
  .card-text-clamped {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    margin-bottom: 0px;
  }
  .card-title::before {
    content: " ";
    position: absolute;
    width: 30px;
    height: 0;
    left: -18px;
    top: 0px; // border-width: 22px 22px;
    border-width: 0.95em 0.95em;
    border-style: solid;
    border-color: #10ab11 #10ab11 #10ab11 rgba(255, 255, 255, 0);
    box-sizing: border-box;
     z-index: -1;
  }
  .card-title {
    margin-bottom: 35px !important;
    display: block; // height: 44px;
    height: 2em;
    width: 100%;
    position: relative;
    right: 0px;
    left: -20px;
    margin-top: 0;
    top: 15px;
    padding: 8px 0 0 25px !important;
    color: #fff !important;
    background-color: #10ab11;
    text-align: left;
    font-size: 17px !important;
    border-top: 0px solid #363636;
    border-bottom: 0px solid #202020;
    border-radius: 0px 0px 0px 0px;
    box-shadow: 3px 0px 3px 0px rgba(00, 00, 00, 0.5);
    font-family: "Lora", serif;
    font-weight: 700;
     z-index: 1;
  }
  .card-title::after {
    content: " ";
    position: absolute;
    width: 45px; // height: 44px;
    height: 2em;
    right: -45px;
    top: 0px;
    background-color: #10ab11;
    border-top: 0px solid #363636;
    border-bottom: 0px solid #202020;
    border-radius: 0px 4px 0px 0px;
    box-shadow: 3px 0px 3px 0px rgba(00, 00, 00, 0.5);
  }
  .card-image {
    margin-top: -65px;
  }
  .detaillink {
    //margin-top: auto;
    margin-bottom: 15px;
    margin-top: 0px;
    display: block; //height: 36px;
    height: 2em;
    width: 100%;
    position: relative;
    right: 0px;
    left: 25px;
    top: 15px;
    padding: 5px 32px 0px 0px;
    color: #fff !important;
    background-color: #1b4695;
    text-align: right;
    font-size: 16px !important;
    border-top: 0px solid #363636;
    border-bottom: 0px solid #202020;
    border-radius: 0px 4px 0px 0px;
    box-shadow: 3px 0px 3px 0px rgba(00, 00, 00, 0.5);
    font-family: "Lora", serif;
    font-weight: 700;
  }
  .detaillink::before {
    content: " ";
    position: absolute;
    width: 24px;
    height: 0;
    left: -27px;
    top: 0px; // border-width: 18px 18px;
    border-width: 1.0em 1.0em;
    border-style: solid;
    border-color: #1b4695 #1b4695 #1b4695 rgba(255, 255, 255, 0);
  }
  .detaillink:visited {
    color: #fff;
    font-family: "Lora";
    font-weight: 700;
  }
  .detaillink:hover {
    text-decoration: underline;
  }
  .nobgcard {
    background-color: #10ab1000 !important;
    color: #ffffff00 !important;
    padding: 0, 0, 0, 0 !important;
  }
  .greencard {
    background-color: #10ab10 !important;
    color: #ffffff !important;
    padding: 0, 0, 0, 0 !important;
  }
  .greencardtitle {
    background-color: #ffffff;
    color: #10ab10 !important;
    font-size: 16px !important;
    padding-left: 10px !important;
  }
  .greencardtitle::before {
    width: 45px; //height: 44px;
    height: 2em;
    left: -5px;
    top: 0px;
    background-color: #ffffff;
    border-top: 0px solid #363636;
    border-bottom: 0px solid #202020;
    border-radius: 4px 0px 0px 0px;
    box-shadow: 0px 0px 0px 0px rgba(00, 00, 00, 0.5);
    border-width: 0px 0px;
  }
  .greencardtitle::after {
    background-color: #ffffff
  }
  .cardimage {
    padding-left: 10px;
    padding-right: 10px;
  }
  .silentbox-item {
    margin-right: .5rem;
    margin-left: .5rem;
    margin-bottom: 1rem;
    //border-radius: .5rem;
    overflow: hidden;
    float: right;

  }
  #silentbox-overlay {
  z-index: 2000 !important;
}

</style>