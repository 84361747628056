<template>
    <b-row class="rowbg">
        <b-col>
            <b-link to="/contact">
                <b-img fluid center  v-bind:src="$imagestore1+'/divers/brief_contact.png'"></b-img>
            </b-link>
            <b-card class="greencard">
                <b-card-title class="greencardtitle">Videoclips</b-card-title>
                <center>
                    <silent-box class="silent-box-item" :image="galleryvideos[0]"></silent-box>
                </center>
            </b-card>
            <b-card class="greencard">
                <b-card-title class="greencardtitle">Referenties</b-card-title>
                <b-carousel id="carousel-1"  >




                    <b-carousel-slide >
                         <div  slot="img" > <div class="carouseltekst">Mooi aaneengesloten, goede spanningsboog. Kinderen onder de indruk, enthousiast en tevreden. Zelf erg te spreken over het verloop en de ontzorging van ons als organisatie.<br><br>Jan van Schaik  - SRON</div> </div>
                    </b-carousel-slide>
                    <b-carousel-slide >
                        <div  slot="img" > <p class="carouseltekst"> Voor de 4e keer op rij hebben wij Piet & Sint op bezoek gehad. Super!! De kinderen zijn weer helemaal gelukkig. Tot volgend jaar!<br><br>Dirk en Marlotte Tammer </p></div>
                    </b-carousel-slide>
                    <b-carousel-slide >
                        <div  slot="img" ><p class="carouseltekst"> Bedankt voor het mooie uur met Piet en Sint. Mooi om te zien dat je inderdaad de spannings boog kan tunen, er is geen doods moment geweest. Volledig geslaagd. Bedankt!<br><br>Jan-Ots Hilling  - KPN</p></div>
                    </b-carousel-slide>
                      <b-carousel-slide >
                        <div  slot="img" ><p class="carouseltekst"> Het hoogtepunt was wel het verhaal over de paardenrace en het vormen van het orkest met de kinderen met dans en muziek.<br><br>Marieke Leek  - GSK</p></div>
                    </b-carousel-slide>
                      <b-carousel-slide >
                        <div  slot="img" ><p class="carouseltekst">Ik vind dat jullie hier erg professioneel in zijn. Ook met bijvoorbeeld de controlelijst voor ons op de dag zelf. Of het bezoek voor af om zaken af te stemmen.<br><br> Marco van Tol - Blokker BV </p></div>
                    </b-carousel-slide>
                      <b-carousel-slide >
                        <div  slot="img" ><p class="carouseltekst">Alles was een hoogtepunt. Goed optreden met hoog entertainment gehalte waarbij het kind de hele tijd centraal staat. Maar ook aandacht voor ouders.<br><br>Anita Gerrits - NedGraphics </p></div>
                    </b-carousel-slide>
                </b-carousel>
            </b-card>
            <b-card class="greencard">
                <b-card-title class="greencardtitle">Social Media</b-card-title>
                <b-link href="https://www.instagram.com/ridder_en_de_prins/" target="_blank">
                    <svg class="social-icon" xmlns="http://www.w3.org/2000/svg" width="23%" height="29%" viewBox="0 0 24 24" fill="white"><rect width="100%" height="100%" fill="#1b4695"/> 
                         <path  d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3"/></svg>                        
                </b-link>
                <b-link href="https://www.youtube.com/channel/UC1yax_0nsBW4oceMZQ6TEuw" target="_blank">
                    <svg class="social-icon" xmlns="http://www.w3.org/2000/svg" width="23%" height="29%" viewBox="0 0 24 24" fill="#1b4695"><rect width="100%" height="100%" fill="white"/> <path d="M10 9.333l5.333 2.662-5.333 2.672v-5.334zm14-9.333v24h-24v-24h24zm-4 12c-.02-4.123-.323-5.7-2.923-5.877-2.403-.164-7.754-.163-10.153 0-2.598.177-2.904 1.747-2.924 5.877.02 4.123.323 5.7 2.923 5.877 2.399.163 7.75.164 10.153 0 2.598-.177 2.904-1.747 2.924-5.877z"/></svg>
                </b-link>
                <b-link href="https://www.facebook.com/ridder.ende.prins" target="_blank">
                    <svg class="social-icon" xmlns="http://www.w3.org/2000/svg" width="23%" height="29%" viewBox="0 0 24 24" fill="#1b4695"><rect width="100%" height="100%" fill="white"/> <path d="M0 0v24h24v-24h-24zm16 7h-1.923c-.616 0-1.077.252-1.077.889v1.111h3l-.239 3h-2.761v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z"/></svg>
                </b-link>
                <b-link href="http://nl.linkedin.com/company/ridder-en-de-prins" target="_blank">
                    <svg class="social-icon" xmlns="http://www.w3.org/2000/svg" width="23%" height="29%" viewBox="0 0 24 24" fill="#1b4695"><rect width="100%" height="100%" fill="white"/> <path d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z"/></svg>
                </b-link>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: 'Zijbalk',
        data: function() {
            return {
                galleryvideos: [{
                        src: 'https://www.youtube.com/embed/4HT4sLyL86E',
                        thumbnail: this.$imagestore2 + "listings/o/40.jpg"
                    },
                    {
                        src: 'https://www.youtube.com/embed/4HT4sLyL86E'
                    },
                    {
                        src: 'https://www.youtube.com/embed/4HT4sLyL86E'
                    },
                    {
                        src: 'https://www.youtube.com/embed/4HT4sLyL86E'
                    },
                    {
                        src: 'https://www.youtube.com/embed/4HT4sLyL86E'
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .carousel-caption{
        padding: 0px !important;
        padding-bottom: 0px;
    }
    .carousel-inner {
        height: 90% !important;
    }
    .carousel-item{
        
        height: 6rem;
        @media (min-width: 767px) {
            height: 14rem !important;
        }
        @media (min-width: 992px) {
            height: 9rem !important;

        }
    }
    .silent-box-item {
        max-width: 100%;
        max-height: 100%;
        overflow: hidden;
    }
    .carouseltekst {
        text-align: center;
        font-size: 14px;
        line-height: 1;
    }
</style>