// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import VueSilentbox from 'vue-silentbox'
import Vuelidate from 'vuelidate'
import axios from 'axios'

Vue.prototype.$http = axios;
Vue.prototype.axios = axios;
Vue.prototype.$mediaurl    = 'https://media.ridderendeprins.nl'
Vue.prototype.$imagestore1 = Vue.prototype.$mediaurl + '/images/RedP'
Vue.prototype.$imagestore2 = Vue.prototype.$mediaurl + '/'

Vue.config.productionTip = false

Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(VueSilentbox);

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

/* eslint-disable no-new */
new Vue({
  render: h => h(App),
  router
}).$mount('#app')



