<template>
    <b-navbar class="navbar-custom" type="dark" toggleable="md" sticky v-bind:class="navbarClassObject">
        <!-- Right aligned nav items -->
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav class="ml-auto">
            <b-navbar-nav align="right">
                <b-nav-item-dropdown text="Ridder en de Prins">
                    <b-dropdown-item to="/nieuws">Nieuws</b-dropdown-item>
                    <b-dropdown-item to="/wie">Wie zijn wij?</b-dropdown-item>
                    <b-dropdown-item to="/missie">Missie en Visie</b-dropdown-item>
                    <b-dropdown-item to="/roetveeg">Roetveeg</b-dropdown-item>
                    <b-dropdown-item to="/videoclipsenfotos">Videoclips en Foto's</b-dropdown-item>
<!--                   <b-dropdown-item to="/corona">Corona protocol</b-dropdown-item>-->
                </b-nav-item-dropdown>
                <b-nav-item to="/sinterklaasshow">Sinterklaasshow</b-nav-item>
                <b-nav-item to="/voorstelling">Voorstelling</b-nav-item>
                <b-nav-item to="/huisbezoek">Huisbezoek</b-nav-item>
                <b-nav-item to="/contact">Contact</b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
    export default {
        name: "Nav",
        computed: {
            navbarClassObject: function () {
                return {
                    "navbar-transparent": this.navTransparent
                }
            }
        },
        props: [
            'navTransparent'
        ],
    };
</script>
  
<style lang="scss">
    .navbar-custom {
        max-width: 1140px;
        margin: 0 auto;
        margin-top: -108px;
        padding-left: 6rem !important;

        @media (max-width: 768px) {
            margin-top: -60px;
            padding-left: 0 !important;
            justify-content: center !important;
        }
        @media (min-width: 992px) {
            padding-left: 20rem !important;
            margin-top: -107px;
        }
        @media (min-width: 1200px) {
            padding-left: 30rem !important;
            margin-top: -120px;
        }
        margin-bottom: 0px;
        margin-left: -15px;
        margin-right: -15px;
        background-color: #1b4695;
        font-family: "Lora",
        serif;
        font-weight: 700;
    }
    .navbar-transparent {
        background-color: rgba($color: #1b4695, $alpha: 1.0);
        @media (min-width: 768px) {
            background-color:rgba($color: #1b4695, $alpha: 0.0);
        }
    }
    .dropdown-menu {
        background-color:rgba($color: #1b4695, $alpha: 1.0) !important;
    }
    .dropdown-item {
        color: #dde1e6;
        font-family: "Lora", serif;
    }
</style>