<template>
    <b-row class="rowbg">
        <b-col md="12">
            <b-card>
                <b-card-title>Contactgegevens</b-card-title>
                <b-card-text>
                    <b-row>
                        <b-col md="6">
                            <p><b>Postadres:</b> <br>Ridder en de Prins Entertainment<br> Slinger 2<br>3995DE Houten<br><br> <b>KvK:</b> 30246771<br> <b>Rekeningnummer:</b> NL36 RABO 0.1453.76.702<br> <b>BTW-nummer:</b> 8199.28.537.B.01</p>
                        </b-col>
                        <b-col md="6">
                            <p>
                            <b-link to="/contact">
                            <b-img   class="w-25"   v-bind:src="$imagestore1+'/logo/cnct.png'"> </b-img>
                            </b-link>
                            </p>
                            
                            <p>
                            <b>E-mail:</b> <b-link href="mailto:info@ridderendeprins.nl"><br>info@ridderendeprins.nl</b-link><br> <b>Telefoon:</b>
                            <br> 030 - 207 702 7
                            </p>
                        </b-col>
                    </b-row>
              
                    <center>
                        <br>
                        <br>
                        <h6>
                        <b-link to="disclaimer">Disclaimer</b-link> | <b-link to="voorwaarden">Voorwaarden</b-link> | <b-link to="privacy-statement">Privacy statement</b-link><br> Ridder en de Prins Entertainment | <b-link href="https://www.sonicity.nl" target="_blank"> Sonicity</b-link>
                        </h6>
                    </center>
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: 'Voettekst'
    }
</script>

<style>

h6 {
    font-size: .8rem !important;
}
</style>