<template>
    <div class="pageheader" :style="{ backgroundImage: 'url(' + image + ')' }">
        <b-link to="/">
            <b-img class="logoimage"  v-bind:src="$imagestore1+'/logo/RedP_Logo_-RedP01.png'"></b-img>
        </b-link>
    </div>
</template>

<script>
    export default {
        name: 'Kop',
        data: function() {
            return {
                backimage: [{
                        image: this.$imagestore1 + "/header/wimpel2020_1.png",
                        route: ""
                    },
                    {
                        image: this.$imagestore1 + "/header/wimpel2020_2.png",
                        route: ""
                    },
 
                    {
                        image: this.$imagestore1 + "/header/wimpel2020_3.png",
                        route: ""
                    
                    },
                                       {
                        image: this.$imagestore1 + "/header/wimpel2020_4.png",
                        route: ""
                    },
                      
                    {
                        image: this.$imagestore1 + "/header/wimpel2023_1.png",     
                        route: ""
                    },
                    {
                        image: this.$imagestore1 + "/header/wimpel2023_2.png",     
                        route: ""
                    },
                ]
            }
        },
        computed: {
            image() {
                //pick random image . The -1 is a pure hack to avoid the Fladderen banner. 

                var chosenNumber = Math.floor(Math.random() * (this.backimage.length ));

                // Check if current route is in backimage.route and overwrite chosennumber

                for (let i=0; i < this.backimage.length; i++) 
                {
                        if (this.backimage[i].route == this.$route.name) 
                        {   
                            chosenNumber = i;
                        }
                }
                return this.backimage[chosenNumber].image;
            }
        }
    };
</script>



<style lang="scss">
.logoimage {
    height: 40%;
    position: relative;
    left: 5%;
    top: 5%;

}
    .pageheader {
        background-size: contain;
        background-repeat: no-repeat;

        @media (max-width: 576px) {
            overflow: hidden;
            background-size: cover;

            height: 260px;
            margin-left: -44px;
            margin-right: -10px;
        }
        @media (min-width: 576px) {
            height: 250px;
            margin-left: -45px;
            margin-right: -45px;
        }
        @media (min-width: 768px) {
            height: 315px;
            margin-left: -56px;
            margin-right: -56px;
        }
        @media (min-width: 992px) {
            height: 390px;
            margin-left: -67px;
            margin-right: -67px;
        }
        @media (min-width: 1200px) {
            height: 460px !important;
            margin-left: -78px;
            margin-right: -78px;
        }
    }
</style>